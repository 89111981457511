<template>
  <div
    v-if="order"
    class="add-new-item-admin"
  >
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.orderCreate')"
      :title="$t('messages.orderCreate')"
      @submitPressed="submitPressed"
    />
    <order-form :order-obj="order" :action="action" type="add" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue';

export default {
  components: {
    TitleBar,
    OrderForm
  },
  data() {
    return {
      action: null,
      order: {
        statusSelect: {
          label: 'New',
          id: 'NEW'
        },
        client: {},
        guests: []
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    },
    clearAction() {
      this.action = null;
    },
  }
};
</script>

<style lang="scss">
</style>
